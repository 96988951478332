import * as React from 'react'
import ResizeObserver from 'resize-observer-polyfill'

import { SubscriptionPaymentType } from '@thg-commerce/enterprise-components/ProductVariations/src/ProductVariations'
import { PurchaseOptions } from '@thg-commerce/enterprise-components/PurchaseOptions'
import { SubscriptionChoice } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Product/ProductPage'
import { SubscriptionContract } from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  Price,
  PriceProps,
  QuantitySelector,
} from '@thg-commerce/gravity-elements'
import { ProductImageProps } from '@thg-commerce/gravity-elements/Product/ProductImage/ProductImage'
import { QuantitySelectorProps } from '@thg-commerce/gravity-elements/QuantitySelector/QuantitySelector'
import {
  ProductOptions,
  ProductOptionsProps,
  QuickBuyComponent,
  QuickBuyModalThemeInterface,
  SelectedOptions,
  Tags,
} from '@thg-commerce/gravity-patterns'
import { useBreakpoint } from '@thg-commerce/gravity-system'
import { BreakpointType, spacing } from '@thg-commerce/gravity-theme'

import {
  AddToBasketButton,
  AvailabilityMessage,
  ContentContainer,
  ModalContentWrapper,
  ModalFooterContainer,
  ModalTitle,
  PricingSection,
  ProductTagsContainer,
  ProductTitle,
  QuantityPlatformMessage,
  QuantityText,
  StyledErrorMessage,
  StyledPageMessage,
  StyledProductImage,
  SubscriptionInfoMessage,
  ViewInfoButton,
} from './styles'

export interface QuickBuyModalContentProps {
  i18nText: {
    modalTitle: string
    rrpLabel: string
    saveLabel: string
    addToBasket: string
    viewMoreInformation: string
    missingProductMessage: string
    maxQuantityBasketItemMessageText: string
    subscriptionInfoMessageText: string
    subscriptionContractDiscountMessage: string | null
    availabilityMessage?: string
    failedToAddToBasketError?: string
    offLabel: string
  }
  product?: {
    sku: string
    title: string
    image: Omit<ProductImageProps, 'width' | 'height'>
    tags?: string[]
    inStock: boolean
    subscriptionData?: {
      productInBasketWithSubscriptionContract: boolean
      productInBasketWithoutSubscriptionContract: boolean
      selectedFrequencyId: string
      enableSubscriptionContracts?: boolean
      activeTabIndex: number
      subscriptionPurchaseOptions?: {
        setActiveTabIndex: (index: number) => void
        activeTabIndex: number
        selectedFrequencyId: string
        setSelectedFrequencyId: (id: string) => void
        showSavingsAmountOnSubscriptionCard: boolean
        selectedOption?: SelectedOptions
        onOptionChange?: (key: string, value: string) => void
        isSubscription: boolean
        subscribeOptions?: {
          upfront: SubscriptionChoice[]
          onDispatch: SubscriptionChoice[]
        }
        subscriptionPaymentType?: SubscriptionPaymentType
        subscriptionContracts: SubscriptionContract[]
        isQuickBuy: boolean
        purchaseOptionsStyle: any
      }
      displaySubscriptionChoicesOnly?: boolean
      displaySubscription: boolean
      isBasketEmpty?: boolean
    }
    notifyWhenInStockEnabled?: boolean
  }
  productOptions: ProductOptionsProps & {
    i18nText: {
      swatch: {
        unavailableText: string
        closeButtonText: string
        showLessButtonText: string
        showMoreButtonText: string
      }
      imageSwatch: {
        showButtonText: string
        showMoreButtonText: string
        showLessButtonText: string
      }
      dropdown: {
        unavailableText: string
      }
      wishlist: {
        unavailableText: string
      }
    }
    selectedOptions?: {
      [option: string]: string
    }
  }
  callbacks: {
    addedToBasketClicked: (
      selected: { [option: string]: string },
      quantity: number,
    ) => void
    quantityChanged?: (quantity: number) => void
    viewMoreInformationClicked?: (
      event: React.MouseEvent<Element, MouseEvent>,
    ) => void
    addToBasketWithContractsCallback?: (
      quantity: number,
      contractId: string,
    ) => void
  }
  quantitySelector: Omit<
    QuantitySelectorProps,
    'editable' | 'quantityChangedCallback'
  >
  quickBuyModalTheme: QuickBuyModalThemeInterface
  product?: {
    sku: string
    title: string
    image: Omit<ProductImageProps, 'width' | 'height'>
    inStock: boolean
    tags?: string[]
    subscriptionData?: {
      productInBasketWithSubscriptionContract: boolean
      productInBasketWithoutSubscriptionContract: boolean
      selectedFrequencyId: string
      activeTabIndex: number
      displaySubscription: boolean
      enableSubscriptionContracts?: boolean
      subscriptionPurchaseOptions?: {
        setActiveTabIndex: (index: number) => void
        activeTabIndex: number
        selectedFrequencyId: string
        setSelectedFrequencyId: (id: string) => void
        showSavingsAmountOnSubscriptionCard: boolean
        subscriptionContracts: SubscriptionContract[]
        isQuickBuy: boolean
        purchaseOptionsStyle: any
        isSubscription: boolean
        selectedOption?: SelectedOptions
        onOptionChange?: (key: string, value: string) => void
        subscribeOptions?: {
          upfront: SubscriptionChoice[]
          onDispatch: SubscriptionChoice[]
        }
        subscriptionPaymentType?: SubscriptionPaymentType
      }
      displaySubscriptionChoicesOnly?: boolean
      isBasketEmpty?: boolean
    }
    notifyWhenInStockEnabled?: boolean
  }
  pricing?: PriceProps['pricing']
  links?: {
    productUrl: string
  }
  hideQuantitySelector?: boolean
  coreTheme?: any
  setOpen?: (state: boolean) => void
  inStockComponent?: React.ReactElement
  displayViewMoreInfo?: boolean
}

interface QuickBuyComponentsProps {
  quickBuyModalContentProps: QuickBuyModalContentProps
  components: BreakpointType<(QuickBuyComponent | undefined)[]>
  selectedQuantity: number
  setSelectedQuantity: React.Dispatch<React.SetStateAction<number>>
}

const INITIAL_QUANTITY = 1

const slotComponentMap: {
  [component in QuickBuyComponent]: (
    {
      i18nText,
      product,
      productOptions,
      pricing,
      links,
      quantitySelector,
      hideQuantitySelector,
      callbacks,
      quickBuyModalTheme,
      coreTheme,
      inStockComponent,
      setOpen,
    }: QuickBuyModalContentProps,
    selectedQuantity: number,
    setSelectedQuantity: React.Dispatch<React.SetStateAction<number>>,
  ) => React.ReactNode
} = {
  title: ({ product }: QuickBuyModalContentProps) => {
    if (!product) {
      return null
    }

    return (
      <div style={{ gridArea: QuickBuyComponent.TITLE }}>
        <ProductTitle reducedBottomMargin={!!(product.tags?.length || 0 > 0)}>
          {product.title}
        </ProductTitle>
        {product.tags && (product.tags?.length || 0) > 0 && (
          <ProductTagsContainer>
            <Tags tags={product.tags} />
          </ProductTagsContainer>
        )}
      </div>
    )
  },
  options: ({
    product,
    productOptions,
    quickBuyModalTheme,
    inStockComponent,
  }: QuickBuyModalContentProps) => {
    return (
      <div style={{ gridArea: QuickBuyComponent.OPTIONS }}>
        {product?.subscriptionData?.displaySubscription && (
          <PurchaseOptions
            {...product.subscriptionData.subscriptionPurchaseOptions}
            selectedOptions={productOptions.selectedOptions}
            onOptionChange={productOptions.onOptionChange}
          />
        )}
        {!product?.subscriptionData?.displaySubscriptionChoicesOnly && (
          <ProductOptions
            {...productOptions}
            order={quickBuyModalTheme.productOptions?.order}
            dropdownWidth={quickBuyModalTheme.productOptions?.dropdown?.width}
            showOnlyTwoRows={quickBuyModalTheme.swatch.showOnlyTwoRows}
            displayColoursAsTextOnly={
              quickBuyModalTheme.swatch.displayColoursAsTextOnly
            }
          />
        )}
        {inStockComponent && inStockComponent}
      </div>
    )
  },
  pricing: (
    props: QuickBuyModalContentProps,
    selectedQuantity: number,
    setSelectedQuantity: React.Dispatch<React.SetStateAction<number>>,
  ) => {
    return (
      <div style={{ gridArea: QuickBuyComponent.PRICING }}>
        <PricingSection
          flexDirection={props.quickBuyModalTheme.pricing.flexDirection}
          alignItems={props.quickBuyModalTheme.pricing.alignItems}
        >
          {props.pricing && (
            <Price
              pricing={props.pricing}
              priceDirection={props.quickBuyModalTheme.priceDirection}
              savings={props.quickBuyModalTheme.pricing.savings}
              i18nText={{
                saveLabel: props.i18nText.saveLabel,
                rrpLabel: props.i18nText.rrpLabel,
                offLabel: props.i18nText.offLabel,
              }}
              savingsPercentage={
                props.quickBuyModalTheme.pricing.savingsPercentage
              }
              priceTheme={props.quickBuyModalTheme.pricing.productPrice}
              discountMessageProps={{
                text: props.i18nText.subscriptionContractDiscountMessage,
                textStyle:
                  props.quickBuyModalTheme.pricing?.discountMessage?.textStyle,
              }}
            />
          )}
          {!props.hideQuantitySelector && (
            <div style={{ marginBottom: `${spacing(2)}` }}>
              <QuantityText>
                {props.quantitySelector.i18nText.quantityLabel}
              </QuantityText>
              <QuantitySelector
                {...props.quantitySelector}
                quantityChangedCallback={(quantity) => {
                  quantity && setSelectedQuantity(quantity)
                }}
                editable={true}
                quantity={selectedQuantity}
              />
            </div>
          )}
        </PricingSection>
      </div>
    )
  },
  addToBag: (props: QuickBuyModalContentProps, selectedQuantity: number) => {
    const allowAddToBasket =
      props.productOptions.selectedOptions &&
      Object.keys(props.productOptions.selectedOptions)?.length ===
        props.productOptions.options?.length &&
      selectedQuantity &&
      props.product?.inStock

    const allowAddToBasketAsSubscription =
      props.product?.subscriptionData?.enableSubscriptionContracts &&
      props.product.subscriptionData.subscriptionPurchaseOptions
        ?.activeTabIndex === 1 &&
      !!props.product.subscriptionData.subscriptionPurchaseOptions
        .subscriptionContracts.length

    const disableAddToBasketSubscriptions =
      props.product?.subscriptionData?.enableSubscriptionContracts &&
      (props.product?.subscriptionData
        ?.productInBasketWithSubscriptionContract ||
        (props.product?.subscriptionData
          ?.productInBasketWithoutSubscriptionContract &&
          !!props.product.subscriptionData.subscriptionPurchaseOptions
            ?.subscriptionContracts.length))

    return (
      <AddToBagContainer
        allowAddToBasket={!!allowAddToBasket}
        allowAddToBasketAsSubscription={!!allowAddToBasketAsSubscription}
        disableAddToBasketSubscriptions={!!disableAddToBasketSubscriptions}
        selectedQuantity={selectedQuantity}
        {...props}
      />
    )
  },
  availability: (props: QuickBuyModalContentProps) => {
    return (
      <div style={{ gridArea: QuickBuyComponent.AVAILABILITY }}>
        {props.i18nText.availabilityMessage && (
          <AvailabilityMessage>
            {props.i18nText.availabilityMessage}
          </AvailabilityMessage>
        )}
      </div>
    )
  },
  viewProduct: (props: QuickBuyModalContentProps) => {
    return (
      <div style={{ gridArea: QuickBuyComponent.VIEWPRODUCT }}>
        {props.links && (
          <ViewInfoButton
            emphasis="medium"
            href={props.links.productUrl}
            renderedAs="a"
            onClick={() => {
              props.callbacks?.viewMoreInformationClicked &&
                props.callbacks.viewMoreInformationClicked
              props.setOpen && props.setOpen(false)
            }}
          >
            {props.i18nText.viewMoreInformation}
          </ViewInfoButton>
        )}
      </div>
    )
  },
}

const AddToBagContainer = (
  props: Pick<
    QuickBuyModalContentProps,
    'productOptions' | 'callbacks' | 'i18nText' | 'product' | 'quantitySelector'
  > & {
    allowAddToBasket: boolean
    allowAddToBasketAsSubscription: boolean
    disableAddToBasketSubscriptions: boolean
    selectedQuantity: number
  },
) => {
  return (
    <div style={{ gridArea: QuickBuyComponent.ADDTOBAG }}>
      <AddToBasketButton
        emphasis="high"
        disabled={
          !props.allowAddToBasket ||
          props.quantitySelector.maxValue < 1 ||
          props.disableAddToBasketSubscriptions
        }
        onClick={() => {
          if (
            props.allowAddToBasket &&
            props.selectedQuantity &&
            props.productOptions.selectedOptions
          ) {
            if (props.allowAddToBasketAsSubscription) {
              props.callbacks.addToBasketWithContractsCallback &&
                props.callbacks.addToBasketWithContractsCallback(
                  props.selectedQuantity,
                  props.product?.subscriptionData?.subscriptionPurchaseOptions
                    ?.selectedFrequencyId!,
                )
            } else {
              props.callbacks.addedToBasketClicked(
                props.productOptions.selectedOptions,
                props.selectedQuantity,
              )
            }
          }
        }}
      >
        {props.i18nText.addToBasket}
      </AddToBasketButton>
    </div>
  )
}

const QuickBuyComponents = (props: QuickBuyComponentsProps) => {
  const breakpoint = useBreakpoint()

  return (
    <ContentContainer>
      {props.components[breakpoint].map(
        (component: QuickBuyComponent | undefined) => {
          if (!component) {
            return null
          }

          return slotComponentMap[component](
            props.quickBuyModalContentProps,
            props.selectedQuantity,
            props.setSelectedQuantity,
          )
        },
      )}
    </ContentContainer>
  )
}

const ModalTopContent = (props: {
  i18nText: {
    subscriptionInfoMessageText: string
    maxQuantityBasketItemMessageText: string
    failedToAddToBasketError?: string
  }
  productSku: string
  quantitySelectorMaxValue: number
  hasSubscriptionInfoText?: boolean
}) => (
  <React.Fragment>
    {props.i18nText.failedToAddToBasketError && (
      <StyledErrorMessage
        error={props.i18nText.failedToAddToBasketError}
        id={`quick-buy-add-to-basket-error-${props.productSku}`}
      />
    )}
    {props.hasSubscriptionInfoText && (
      <SubscriptionInfoMessage
        text={props.i18nText.subscriptionInfoMessageText}
        type="info"
      />
    )}
    {!props.quantitySelectorMaxValue && (
      <QuantityPlatformMessage
        type="info"
        text={props.i18nText.maxQuantityBasketItemMessageText}
        data-testid="quantity-basket-item"
      />
    )}
  </React.Fragment>
)

const errorMessage = (errorText) => {
  return (
    <StyledPageMessage
      flashMessageId="quickBuyModalMissingProduct"
      message={errorText}
      type="error"
    />
  )
}

const allowAddToBasket = (productOptions, selectedQuantity, inStock) =>
  productOptions.selectedOptions &&
  Object.keys(productOptions.selectedOptions)?.length ===
    productOptions.options.length &&
  selectedQuantity &&
  inStock

const ModalBottomContent = (
  props: Pick<
    QuickBuyModalContentProps,
    'i18nText' | 'productOptions' | 'callbacks' | 'links' | 'setOpen'
  > & {
    modalButtonsRef: React.RefObject<HTMLDivElement>
    modalContentScrollable: boolean
    allowAddToBasket: boolean
    selectedQuantity: number
    displayViewMoreInfo?: boolean
  },
) => (
  <React.Fragment>
    <ModalFooterContainer
      ref={props.modalButtonsRef}
      modalContentScrollable={props.modalContentScrollable}
    >
      <AddToBasketButton
        emphasis="high"
        disabled={!props.allowAddToBasket}
        onClick={() => {
          if (
            props.allowAddToBasket &&
            props.selectedQuantity &&
            props.productOptions.selectedOptions
          ) {
            props.callbacks.addedToBasketClicked(
              props.productOptions.selectedOptions,
              props.selectedQuantity,
            )
          }
        }}
      >
        {props.i18nText.addToBasket}
      </AddToBasketButton>
      {props.links && !props.displayViewMoreInfo && (
        <ViewInfoButton
          emphasis="medium"
          href={props.links.productUrl}
          renderedAs="a"
          onClick={() => {
            props.callbacks?.viewMoreInformationClicked &&
              props.callbacks.viewMoreInformationClicked
            props.setOpen && props.setOpen(false)
          }}
        >
          {props.i18nText.viewMoreInformation}
        </ViewInfoButton>
      )}
    </ModalFooterContainer>
  </React.Fragment>
)

export const QuickBuyModalContent = ({
  i18nText,
  product,
  productOptions,
  pricing,
  links,
  quantitySelector,
  hideQuantitySelector,
  callbacks,
  quickBuyModalTheme,
  coreTheme,
  setOpen,
  inStockComponent,
  displayViewMoreInfo,
}: QuickBuyModalContentProps) => {
  const [selectedQuantity, setSelectedQuantity] = React.useState<number>(
    INITIAL_QUANTITY,
  )
  const [modalContentScrollable, setModalContentScrollable] = React.useState<
    boolean
  >(false)
  const modalContentRef = React.useRef<HTMLDivElement>(null)
  const modalButtonsRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!modalContentRef?.current || !modalButtonsRef?.current) {
      return
    }

    let resizeObserver: ResizeObserver | undefined
    resizeObserver = new ResizeObserver(() => {
      const modalContentRect = modalContentRef?.current?.getBoundingClientRect()

      const modalButtonsRect = modalButtonsRef?.current?.getBoundingClientRect()

      if (modalContentRect && modalButtonsRect) {
        setModalContentScrollable(
          modalContentRect.top + modalContentRect.height > modalButtonsRect.top,
        )
      }
    })
    resizeObserver.observe(modalContentRef.current)

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [modalContentRef])

  if (!product) {
    return errorMessage(i18nText.missingProductMessage)
  }

  if (!product) {
    return errorMessage(i18nText.missingProductMessage)
  }

  const hasSubscriptionInfoText =
    product?.subscriptionData?.productInBasketWithSubscriptionContract ||
    (product?.subscriptionData?.productInBasketWithoutSubscriptionContract &&
      !!product.subscriptionData?.subscriptionPurchaseOptions
        ?.subscriptionContracts.length)

  return (
    <React.Fragment>
      <ModalTitle>{i18nText.modalTitle}</ModalTitle>
      <ModalTopContent
        i18nText={{
          subscriptionInfoMessageText: i18nText.subscriptionInfoMessageText,
          maxQuantityBasketItemMessageText:
            i18nText.maxQuantityBasketItemMessageText,
          failedToAddToBasketError: i18nText.failedToAddToBasketError,
        }}
        productSku={product.sku}
        hasSubscriptionInfoText={hasSubscriptionInfoText}
        quantitySelectorMaxValue={quantitySelector.maxValue}
      />
      <ModalContentWrapper
        ref={modalContentRef}
        reducedBottomPadding={quickBuyModalTheme.enableStickyFooterButtons}
      >
        <StyledProductImage
          {...product.image}
          width={
            quickBuyModalTheme.product?.image?.width || [
              '100%',
              '100%',
              190,
              191,
            ]
          }
          height={
            quickBuyModalTheme.product?.image?.height || ['', '', 190, 191]
          }
        />
        <QuickBuyComponents
          quickBuyModalContentProps={{
            i18nText,
            product,
            productOptions,
            pricing,
            links,
            quantitySelector,
            hideQuantitySelector,
            callbacks,
            quickBuyModalTheme,
            coreTheme,
            inStockComponent,
            setOpen,
          }}
          components={quickBuyModalTheme.enabledComponents}
          selectedQuantity={selectedQuantity}
          setSelectedQuantity={setSelectedQuantity}
        />
      </ModalContentWrapper>
      {quickBuyModalTheme.enableStickyFooterButtons && (
        <ModalBottomContent
          i18nText={i18nText}
          modalButtonsRef={modalButtonsRef}
          modalContentScrollable={modalContentScrollable}
          allowAddToBasket={
            !!allowAddToBasket(
              productOptions,
              selectedQuantity,
              product.inStock,
            )
          }
          selectedQuantity={selectedQuantity}
          productOptions={productOptions}
          callbacks={callbacks}
          links={links}
          displayViewMoreInfo={displayViewMoreInfo}
        />
      )}
    </React.Fragment>
  )
}
