import * as React from 'react'

import { Modal, ModalProps } from '@thg-commerce/gravity-elements'

import {
  QuickBuyModalContent,
  QuickBuyModalContentProps,
} from './QuickBuyModalContent'
import { QuickBuyModalSkeleton } from './QuickBuyModalSkeleton'

interface QuickBuyModalRendering {
  rendering: {
    open: boolean
    onClose: () => void
  }
  modal: Omit<ModalProps, 'children' | 'onClose' | 'open'>
  loading: boolean
  setOpen?: (state: boolean) => void
  inStockComponent?: React.ReactElement
}

export type QuickBuyModalProps = QuickBuyModalRendering &
  QuickBuyModalContentProps

export const QuickBuyModal = ({
  rendering,
  modal,
  loading,
  i18nText,
  product,
  productOptions,
  pricing,
  links,
  callbacks,
  quantitySelector,
  hideQuantitySelector,
  quickBuyModalTheme,
  coreTheme,
  setOpen,
  inStockComponent,
  displayViewMoreInfo,
}: QuickBuyModalProps) => {
  return (
    <Modal
      open={rendering.open}
      onClose={rendering.onClose}
      contentPadding="0"
      verticalAlignment={['bottom', 'bottom', 'top', 'top']}
      isAutoHeightMobile={true}
      {...modal}
    >
      {loading && !quickBuyModalTheme.animation ? (
        <QuickBuyModalSkeleton />
      ) : (
        <QuickBuyModalContent
          i18nText={i18nText}
          product={product}
          productOptions={productOptions}
          pricing={pricing}
          links={links}
          callbacks={callbacks}
          quantitySelector={quantitySelector}
          hideQuantitySelector={hideQuantitySelector}
          quickBuyModalTheme={quickBuyModalTheme}
          coreTheme={coreTheme}
          setOpen={setOpen}
          inStockComponent={inStockComponent}
          displayViewMoreInfo={displayViewMoreInfo}
        />
      )}
    </Modal>
  )
}
